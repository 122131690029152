import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import createRootReducer from "./reducers";

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { baseApi } from "./baseApi";

// Make sure to update REDUX_STORE_VERSION when you make a structural change
// This will clear the old redux state that was stored in browser cache
const REDUX_STORE_VERSION = 35;
const persistConfig = {
  key: "root",
  storage,
  version: REDUX_STORE_VERSION,
  migrate: (state) => {
    if (state != null) {
      if (state._persist == null) {
        console.warn(
          "Air1 Insurance: Something went wrong with the redux-persist package."
        );
      } else if (state._persist.version !== REDUX_STORE_VERSION) {
        localStorage.clear();
        return Promise.resolve({});
      }
    }
    return Promise.resolve(state);
  },
};

// redux-state-sync allows us to replicate actions between tabs
// Actions defined here will be triggered in all tabs when they are launched
const reduxStateSyncConfig = {
  whitelist: [
    "ERASE_REDUX_STORE",
    "user/logout/fulfilled",
    "user/setIsAuthenticated",
    "user/login/fulfilled",
  ],
};

const persistedReducer = persistReducer(persistConfig, createRootReducer());

export default function configuredStore() {
  let store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(baseApi.middleware)
        .concat(createStateSyncMiddleware(reduxStateSyncConfig));
      return middleware;
    },
  });

  // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
  // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
  setupListeners(store.dispatch);

  initMessageListener(store);
  let persistor = persistStore(store);

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      store.replaceReducer(persistedReducer);
    });
  }

  return { store, persistor };
}
