import { SelectOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { Button, Col, Form, InputNumber, Row, Space } from "antd";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../../../shared/formFields/CustomInput";
import { useStartApplicationMutation } from "../../../../../store/baseApi";
import { createApplicationSchema } from "../formSchema";
import {
  PhoneTypes,
  PhoneTypeSelect,
} from "../../formFields/PhoneTypeAndNumberInput";

const CreateApplicationForm = () => {
  const navigate = useNavigate();
  const [startApplication, { status, data, isLoading }] =
    useStartApplicationMutation();
  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(createApplicationSchema),
  });

  useEffect(() => {
    if (status === QueryStatus.fulfilled) {
      if (data.success === true) {
        navigate(`/all-forms/private-aviation/${data.content.id}`);
      }
    }
  }, [status, data, navigate]);

  const onFinish = (values) => {
    trigger();
    startApplication({
      email: values.email.trim(),
      main_owner_name: values.main_owner_name.trim(),
      phone_numbers: [
        { type: values.phone_number_type, number: values.phone_number },
      ],
      isNew: true,
    });
  };

  return (
    <Form
      name="get_started"
      autoComplete="off"
      layout="vertical"
      labelWrap
      onFinish={() => handleSubmit(onFinish)()}
    >
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <CustomInput
            style={{}}
            formItem={{
              required: true,
              label: "Full name",
              style: { marginBottom: "12px" },
            }}
            disabled={isLoading}
            control={control}
            name="main_owner_name"
            placeholder="Enter your full name"
          />
        </Col>
        <Col span={24}>
          <CustomInput
            formItem={{
              required: true,
              label: "Email",
              style: { marginBottom: "12px" },
            }}
            disabled={isLoading}
            control={control}
            name="email"
            placeholder="Enter your email address"
          />
        </Col>
        <Col span={24}>
          <Form.Item
            label="Phone number"
            required={true}
            name={`phone_numbers`}
            validateStatus={
              errors?.phone_number != null || errors?.phone_number_type != null
                ? "error"
                : ""
            }
            help={
              errors?.phone_number != null || errors?.phone_number_type != null
                ? errors?.phone_number?.message ??
                  errors?.phone_number_type?.message ??
                  ""
                : ""
            }
          >
            <div>
              <Controller
                control={control}
                name={`phone_number`}
                render={({ field }) => (
                  <Space.Compact block>
                    <PhoneTypeSelect
                      control={control}
                      options={[
                        {
                          value: PhoneTypes.HOME,
                          label: "Home Phone",
                        },
                        {
                          value: PhoneTypes.WORK,
                          label: "Work Phone",
                        },
                        {
                          value: PhoneTypes.CELL,
                          label: "Cell Phone",
                        },
                        {
                          value: PhoneTypes.FAX,
                          label: "Fax Number",
                        },
                      ]}
                      name={`phone_number_type`}
                    />
                    <InputNumber
                      placeholder="Phone Number"
                      style={{ width: "100%" }}
                      controls={false}
                      formatter={(value) => {
                        if (value.length >= 7) {
                          return `(${value.slice(0, 3)}) ${value.slice(
                            3,
                            6
                          )}-${value.slice(6)}`;
                        } else if (value.length >= 4) {
                          return `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
                        } else if (value.length === 3) {
                          return `(${value.slice(0, 3)})`;
                        }
                        return value;
                      }}
                      parser={(value) => {
                        let newValue = value.replace("(", "");
                        newValue = newValue.replace(")", "");
                        newValue = newValue.replace("-", "");
                        newValue = newValue.replace(" ", "");
                        return newValue;
                      }}
                      {...field}
                    />
                  </Space.Compact>
                )}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            loading={isLoading}
            htmlType="submit"
            type="primary"
            disabled={Object.keys(errors ?? {}).length > 0}
          >
            {!isLoading && <SelectOutlined />} Start new application
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateApplicationForm;
