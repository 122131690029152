import { isNull } from "lodash";
import * as yup from "yup";
import {
  HANGARED_AIRCRAFT_STORAGE,
  MOORED_AIRCRAFT_STORAGE,
} from "../../../../shared/constants";

export const pilotInformationSchema = yup.object().shape({
  pilots: yup.array().of(
    yup.object().shape({
      pilot_first_name: yup.string().required("Pilot first name required"),
      pilot_last_name: yup.string().required("Pilot last name required"),
      pilot_occupation: yup.string().required("Pilot occupation required"),
      dob_year: yup.number().required("Year required"),
      dob_month: yup.number().required("Month required"),
      dob_day: yup.number().required("Day required"),
      license_ratings: yup
        .array()
        .of(yup.string().required())
        .min(1, "License/Ratings required")
        .required("License/Ratings required"),
      has_had_accidents: yup
        .boolean()
        .required(
          "'Has this pilot had any accidents, claims, license limitations or violations surrender or revocation, or transport canada citations in the last 5 years?' can't be blank"
        ),
      accidents_claim_amount: yup.string().when("has_had_accidents", {
        is: true,
        then: (schema) => schema.required("Claim amount required"),
        otherwise: (schema) => schema.nullable(),
      }),
      recurrent_training: yup.string().nullable(),
      total_all_aircraft: yup
        .number()
        .required("'Total All Aircraft' can't be blank"),
      total_make_model: yup
        .number()
        .required("'Total Make & Model' can't be blank"),
      total_floats: yup.number().nullable(),
      total_amphibian: yup.number().nullable(),
      total_retract: yup.number().nullable(),
      total_tailwheel: yup.number().nullable(),
      total_multi: yup.number().nullable(),
      total_turbine: yup.number().nullable(),
      total_rotor: yup.number().nullable(),
      total_last_twelve_months_all: yup
        .number()
        .required("'Total last 12 months All Aircraft' can't be blank"),
      total_last_twelve_months_make_model: yup
        .number()
        .required("'Total last 12 months Make & Model' can't be blank"),
      notes_and_comments: yup.string().nullable(),
      membership_numbers: yup.array().of(
        yup.object().shape({
          type: yup.string().nullable(),
          number: yup.string().nullable(),
        })
      ),
    })
  ),
});

export const applicantsInformationSchema = yup.object().shape({
  main_owner_name: yup.string().required("Named insured required"),
  contact_person: yup.string().required("Contact person required"),
  address: yup.string().required("Address required"),
  city: yup.string().required("City required"),
  province: yup.string().required("Province required"),
  postal_code: yup
    .string()
    .required("Postal code required")
    .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, "Invalid postal code")
    .typeError("Invalid postal code")
    .min(6, "Invalid postal code")
    .max(7, "Invalid postal code")
    .transform((currentValue) => (currentValue ?? "").toUpperCase()),
  occupation: yup.string().required("Occupation required"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Email invalid"),
  phone_numbers: yup.array().of(
    yup.object().shape({
      type: yup.string().required("Phone number type required"),
      number: yup
        .string()
        .matches(/^[0-9]{10}$/, {
          message: "Invalid phone number",
        })
        .required("Phone number required"),
    })
  ),
});
export const aircraftInformationSchema = yup.object().shape({
  year: yup
    .string()
    .required("Year required")
    .min(4, "Invalid year")
    .max(4, "Invalid year")
    .matches(/^[0-9]+$/, "Invalid year")
    .test("is-valid-year", "Year must be between 1899 and 2050", (value) => {
      const numericValue = parseInt(value, 10);
      return numericValue > 1899 && numericValue < 2050;
    }),
  make: yup.string().required("Make required").min(1, "Make required"),
  model: yup.string().required("Model required").min(1, "Model required"),
  registration: yup
    .string()
    .required("Registration required")
    .min(1, "Registration required"),
  passenger_seats: yup
    .number()
    .required("Number of passenger seats required")
    .min(0, "Minimum number of passenger seats is 0")
    .max(9999, "Maximum number of passenger seats is 9999"),
  wheels_fixed_gear_checkbox: yup.boolean(),
  wheels_fixed_gear_amount: yup.number().when("wheels_fixed_gear_checkbox", {
    is: true,
    then: (schema) => schema.required("Wheels fixed gear amount required"),
    otherwise: (schema) => schema.nullable(),
  }),
  wheels_tailwheel_checkbox: yup.boolean(),
  wheels_tailwheel_amount: yup.number().when("wheels_tailwheel_checkbox", {
    is: true,
    then: (schema) => schema.required("Wheels tailwheel amount required"),
    otherwise: (schema) => schema.nullable(),
  }),
  wheels_retract_checkbox: yup.boolean(),
  wheels_retract_amount: yup.number().when("wheels_retract_checkbox", {
    is: true,
    then: (schema) => schema.required("Wheels retract amount required"),
    otherwise: (schema) => schema.nullable(),
  }),
  floats_checkbox: yup.boolean(),
  floats_amount: yup.number().when("floats_checkbox", {
    is: true,
    then: (schema) => schema.required("Floats amount required"),
    otherwise: (schema) => schema.nullable(),
  }),
  skis_checkbox: yup.boolean(),
  skis_amount: yup.number().when("skis_checkbox", {
    is: true,
    then: (schema) => schema.required("Skis amount required"),
    otherwise: (schema) => schema.nullable(),
  }),
  amphibian_checkbox: yup.boolean(),
  amphibian_amount: yup.number().when("amphibian_checkbox", {
    is: true,
    then: (schema) => schema.required("Amphibian amount required"),
    otherwise: (schema) => schema.nullable(),
  }),
  helicopter_checkbox: yup.boolean(),
  helicopter_amount: yup.number().when("helicopter_checkbox", {
    is: true,
    then: (schema) => schema.required("Helicopter amount required"),
    otherwise: (schema) => schema.nullable(),
  }),
  hull_configuration: yup
    .boolean()
    .when(
      [
        "wheels_fixed_gear_checkbox",
        "helicopter_checkbox",
        "amphibian_checkbox",
        "skis_checkbox",
        "floats_checkbox",
        "wheels_retract_checkbox",
        "wheels_tailwheel_checkbox",
      ],
      {
        is: (a, b, c, d, e, f, g) => !a && !b && !c && !d && !e && !f && !g,
        then: (schema) =>
          schema.required("At least 1 hull configuration required"),
        otherwise: (schema) => schema.nullable(),
      }
    ),
  horse_power: yup.string().required("Horse power & engine make are required"),
  liability_validation: yup
    .string()
    .when(
      [
        "optional_1million_checked",
        "optional_2million_checked",
        "optional_3million_checked",
        "optional_5million_checked",
        "custom_coverage_options",
      ],
      {
        is: (
          optional_1million_checked,
          optional_2million_checked,
          optional_3million_checked,
          optional_5million_checked,
          custom_coverage_options
        ) => {
          const coverageOptionsCount =
            (optional_1million_checked ? 1 : 0) +
            (optional_2million_checked ? 1 : 0) +
            (optional_3million_checked ? 1 : 0) +
            (optional_5million_checked ? 1 : 0) +
            (custom_coverage_options?.length ?? 0);
          return coverageOptionsCount > 0;
        },
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema.required("Please select at least 1 liability coverage option"),
      }
    ),
  will_charge_for_aircraft_use: yup
    .boolean()
    .required(
      "'Will you be charging other people for the use of your aircraft?' can't be blank"
    ),
  will_charge_for_aircraft_use_details: yup
    .string()
    .when("will_charge_for_aircraft_use", {
      is: true,
      then: (schema) => schema.required("Aircraft use details required"),
      otherwise: (schema) => schema.nullable(),
    }),
  will_change_aircraft_configuration: yup
    .boolean()
    .required(
      "'Will you be changing the configuration of your aircraft to amphibious, floats, or skis?' can't be blank"
    ),
  will_change_aircraft_configuration_details: yup
    .string()
    .when("will_change_aircraft_configuration", {
      is: true,
      then: (schema) =>
        schema.required("Configuration changes details required"),
      otherwise: (schema) => schema.nullable(),
    }),
  has_additional_insured: yup
    .boolean()
    .required("'Do you wish to add an additional insured?' can't be blank"),
  additional_insured_name: yup.string().when("has_additional_insured", {
    is: (value) => value === true,
    then: (schema) => schema.required("Additional insured name required"),
    otherwise: (schema) => schema.nullable(),
  }),
  additional_insured_address: yup.string().when("has_additional_insured", {
    is: (value) => value === true,
    then: (schema) => schema.required("Additional insured address required"),
    otherwise: (schema) => schema.nullable(),
  }),
  additional_insured_city: yup.string().when("has_additional_insured", {
    is: (value) => value === true,
    then: (schema) => schema.required("Additional insured city required"),
    otherwise: (schema) => schema.nullable(),
  }),
  additional_insured_postal_code: yup.string().when("has_additional_insured", {
    is: (value) => value === true,
    then: (schema) =>
      schema.required("Additional insured postal code required"),
    otherwise: (schema) => schema.nullable(),
  }),
  additional_insured_province: yup.string().when("has_additional_insured", {
    is: (value) => value === true,
    then: (schema) => schema.required("Additional insured province required"),
    otherwise: (schema) => schema.nullable(),
  }),
  additional_insured_nature: yup.string().when("has_additional_insured", {
    is: (value) => value === true,
    then: (schema) =>
      schema.required(
        "'Reason for being added as an additional insured' can't be blank"
      ),
    otherwise: (schema) => schema.nullable(),
  }),
  has_lienholder: yup
    .boolean()
    .required("'Does your aircraft have a lienholder?' can't be blank"),
  lienholder_name: yup.string().when("has_lienholder", {
    is: (value) => value === true,
    then: (schema) => schema.required("Lienholder name required"),
    otherwise: (schema) => schema.nullable(),
  }),
  lienholder_address: yup.string().when("has_lienholder", {
    is: (value) => value === true,
    then: (schema) => schema.required("Lienholder address required"),
    otherwise: (schema) => schema.nullable(),
  }),
  lienholder_amount: yup.number().when("has_lienholder", {
    is: (value) => value === true,
    then: (schema) => schema.required("Lien amount required"),
    otherwise: (schema) => schema.nullable(),
  }),
  lienholder_postal_code: yup.string().when("has_lienholder", {
    is: (value) => value === true,
    then: (schema) => schema.required("Lienholder postal code required"),
    otherwise: (schema) => schema.nullable(),
  }),
  lienholder_city: yup.string().when("has_lienholder", {
    is: (value) => value === true,
    then: (schema) => schema.required("Lienholder city required"),
    otherwise: (schema) => schema.nullable(),
  }),
  lienholder_province: yup.string().when("has_lienholder", {
    is: (value) => value === true,
    then: (schema) => schema.required("Lienholder province required"),
    otherwise: (schema) => schema.nullable(),
  }),
  engine_hours_new: yup.number().nullable(),
  engine_hours_last: yup.number().nullable(),
  engine2_hours_new: yup.number().nullable(),
  engine2_hours_last: yup.number().nullable(),
  last_aircraft_annual: yup.string().nullable(),
  equipment_checkbox: yup.boolean().nullable(),
  equipment_input: yup.string().when("equipment_checkbox", {
    is: (value) => value === true,
    then: (schema) => schema.required("Equipment list is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  hull_coverage_requested: yup
    .array()
    .of(yup.string().required("'Hull coverage requested' can't be blank"))
    .min(1, "'Hull coverage requested' can't be blank")
    .required("'Hull coverage requested' can't be blank"),
});
export const aircraftInformationExtendedSchema = yup.object().shape({
  flying_outside_canada: yup
    .boolean()
    .required("'Will you be flying outside of Canada?' can't be blank"),
  leaving_canada_amount: yup.number().when("flying_outside_canada", {
    is: (value) => value === true,
    then: (schema) =>
      schema.required(
        "'Roughly how many times will the aircraft be leaving Canada in a 12 month period?' can't be blank"
      ),
    otherwise: (schema) => schema.nullable(),
  }),
  airshows_checkbox: yup
    .boolean()
    .required("'Do you participate in airshows' can't be blank"),
  airshows_input: yup.string().when("airshows_checkbox", {
    is: true,
    then: (schema) => schema.required("Details about airshows required"),
    otherwise: (schema) => schema.nullable(),
  }),

  aircraft_for_aerobatics_checkbox: yup
    .boolean()
    .required("'Do you use the aircraft for aerobatics?' can't be blank"),
  aircraft_for_aerobatics_input: yup
    .string()
    .when("aircraft_for_aerobatics_checkbox", {
      is: true,
      then: (schema) => schema.required("Details about aerobatics required"),
      otherwise: (schema) => schema.nullable(),
    }),

  landing_off_airport_checkbox: yup
    .boolean()
    .required(
      "'Do you plan on landing at off airport locations?' can't be blank"
    ),
  landing_off_airport_input: yup.string().when("landing_off_airport_checkbox", {
    is: true,
    then: (schema) =>
      schema.required("Details about off airport landings required"),
    otherwise: (schema) => schema.nullable(),
  }),
  trip_duration_amount: yup.number().nullable(),
  trip_duration_select: yup.string().when("trip_duration_amount", {
    is: (n) => !isNull(n),
    then: (schema) =>
      schema.required("Longest trip duration 'Months/Days' not selected"),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const airportInformationSchema = yup.object().shape({
  aircraft_home_base: yup.string().required("Home base of aircraft required"),
  aircraft_storage: yup
    .array()
    .of(yup.string().required())
    .min(1, "'Aircraft storage' can't be blank")
    .required("'Aircraft storage' can't be blank"),
  own_hangar_checkbox: yup.boolean().when("aircraft_storage", {
    is: (value) => value?.includes(HANGARED_AIRCRAFT_STORAGE),
    then: (schema) =>
      schema.required("'Do you own the hangar?' can't be blank"),
    otherwise: (schema) => schema.nullable(),
  }),
  moor_duration: yup.string().when("aircraft_storage", {
    is: (value) => value?.includes(MOORED_AIRCRAFT_STORAGE),
    then: (schema) =>
      schema.required(
        "'How long do you moor your aircraft for in a year?' can't be blank"
      ),
    otherwise: (schema) => schema.nullable(),
  }),
  same_legal_entity_checkbox: yup.boolean().when("own_hangar_checkbox", {
    is: true,
    then: (schema) =>
      schema.required(
        "'Is the hangar owned by the same legal entity as your aircraft?' can't be blank"
      ),
    otherwise: (schema) => schema.nullable(),
  }),
  legal_entity_name: yup
    .string()
    .when(["same_legal_entity_checkbox", "own_hangar_checkbox"], {
      is: (same_legal_entity_checkbox, own_hangar_checkbox) =>
        same_legal_entity_checkbox === false || own_hangar_checkbox === false,
      then: (schema) => schema.required("Legal entity of hangar required"),
      otherwise: (schema) => schema.nullable(),
    }),
  type_of_aerodrome: yup.string().required("Type of aerodrome required"),
  runway_surface: yup.string().required("Runway surface required"),
  runway_length: yup.number().required("Runway length required"),
  own_airstrip: yup
    .boolean()
    .required("'Do you own the airstrip?' can't be blank"),
});

export const phone_mask = "(000) 000-0000";

export const phone_types = [
  "home_phone",
  "work_phone",
  "cell_phone",
  "fax_number",
];

export const continueApplicationSchema = yup.object().shape({
  id: yup.string().required("Application number is required"),
});

export const createApplicationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Email is invalid"),
  main_owner_name: yup.string().required("Full name is required"),
  phone_number: yup
    .string()
    .matches(/^[0-9]{10}$/, {
      message: "Invalid phone number",
    })
    .required("Phone number is required"),
  phone_number_type: yup.string().required("Phone type is required"),
});
